<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>
import TimeAgo from 'vue2-timeago';
import VueSlideBar from "vue-slide-bar";
import { OrbitSpinner } from 'epic-spinners';

import {get_acsrf_token, startsWith} from "@/methods";
import { BanType } from "@/enums";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import CopyableInput from "@/components/CopyableInput";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    CopyableInput,
    VueSlideBar,
    OrbitSpinner,
    TimeAgo
  },
  validations: {
    banForm: {
      identifier: {required, custom: true},
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
      description: {maxLength: maxLength(400)}
    }
  },
  methods: {
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/query`);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy ? this.sortBy : 'created_at',
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10)
            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            this.$toast.error('Failed to contact query service');
            if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    },
    addBan() {
      this.$refs.addBanModal.show();
    },
    addLink() {
      let link = String(this.$refs.banFormLinks.value);
      if(!link.length) return;
      if(!link.startsWith('http://') && !link.startsWith('https://')) return;
      this.banForm.links.push(link);
      this.$refs.banFormLinks.value = '';
    },
    removeLink(link) {
      let index = this.banForm.links.indexOf(link);
      if(index !== -1) this.banForm.links.splice(index, 1);
    },
    filePrompt() {
      document.getElementById('file').click();
    },
    async invokeFileUpload(fileName) {
      let index = this.banForm.files.map((e) => {return e.name;}).indexOf(fileName);
      if(index === -1) return;
      try {
        let file = this.banForm.files[index];

        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/upload`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        let data;
        if(!response.ok) {
          if(response.status === 400) {
            data = await response.json();
            if(data.error === 'exhausted') {
              this.$swal({
                icon: 'warning',
                text: this.$t('error.gryphon.quota.message'),
                title: this.$t('error.gryphon.quota.title')
              });
              throw new Error('gryphon blocked');
            }
          } else {
            throw new Error('failed to request resource id');
          }
        } else {
          data = await response.json();
          file.resource_id = data.resource_id;
          if(data.storage_warning) {
            let result = await this.$swal({
              icon: 'warning',
              text: this.$t('error.gryphon.quota_warning.message'),
              title: this.$t('error.gryphon.quota_warning.title'),
              showCancelButton: true,
              confirmButtonText: this.$t('error.gryphon.quota_warning.confirm'),
              confirmButtonColor: '#f46a6a',
              cancelButtonColor: '#c3cbe4',
            });
            if(!result.isConfirmed) throw new Error('cancelled');
          }
        }

        payload = new FormData();
        payload.append('file', file.object);
        payload.append('upload_token', data.upload_token);

        response = await fetch(data.upload_url, {
          method: 'POST',
          body: payload,
          credentials: 'include'
        });
        if(!response.ok) throw new Error('upload failed');
        file.processing = false;
        file.uploaded = true;

      } catch (error) {
        this.removeFile(fileName);
        if(error !== 'cancelled') this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    async addFile(event) {
      let toast = this.$toast;
      let files = event.target.files;
      files.forEach((file) => {
        if(this.banForm.files.length >= 5) {
          this.$toast.error(this.$t('support.request.upload.errors.count', {file: file.name}));
          return;
        }
        if(this.banForm.files.filter((e)=>{if(e.name === file.name) return e;}).length !== 0) return;
        let actualFileSize = file.size / 1024 / 1024; // MiB
        if (actualFileSize > 10) {
          this.$toast.error(this.$t('support.request.upload.errors.size', {file: file.name}));
        } else {
          this.banForm.files.push({
            object: file,
            size: actualFileSize.toPrecision(2),
            name: file.name,
            processing: true,
            error: false,
            uploaded: false,
            identifier: null,
            resource_id: null
          });
          this.invokeFileUpload(file.name);
        }
      });
      this.$refs.file.value = null;
    },
    removeFile(fileName) {
      let index = this.banForm.files.map((e) => {return e.name;}).indexOf(fileName);
      if(index !== -1) this.banForm.files.splice(index, 1);
    },
    identifierChanged() {
      this.identifierValid = true;

      let blocks = this.banForm.identifier.split('.');
      if(blocks.length !== 4)  {
        this.identifierValid = false;
        return;
      }

      blocks.forEach((element) => {
        if(element !== '*') {
          let i = parseInt(element);
          if(isNaN(i)) this.identifierValid = false;
          if(i > 255 || i < 0) this.identifierValid = false;
        }
      });
    },
    async issueBan() {
      this.$v.banForm.$touch();
      if(this.$v.banForm.$error || !this.identifierValid) return;

      this.banInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let files = [];
        this.banForm.files.forEach((file) => {
          files.push([file.resource_id, file.name]);
        });

        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          type: 'ipv4',
          banlist_id: this.banlist_id,
          reason: this.banForm.reason,
          identifier: this.banForm.identifier,
          description: this.banForm.description,
          links: this.banForm.links,
          files: files,
          permanent: this.banForm.permanent,
          expiration: this.banForm.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/ban`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.banForm = {
            type: 'ipv4',
            banlist_id: this.banlist_id,
            identifier: null,
            reason: null,
            description: null,
            links: [],
            files: [],
            permanent: this.banForm.permanent,
            expiration: this.banForm.expiration
          };

          this.$refs.addBanModal.hide();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.add.success')
          });
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.banInProgress = false;
    },
    async toggleRevisionState(banId, force_delete) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          ban_id: banId,
          force_delete: force_delete
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/ban-rework`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async revokeBan(banId, force_delete) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          ban_id: banId,
          force_delete: force_delete
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/ban-revoke`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.remove.success')
          });
          if(this.$refs.featuredBan.$data.isShow) {
            if(data.deleted) {
              this.$refs.featuredBan.hide();
            } else await this.detailedBanView(banId);
          }
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async detailedBanView(banId) {
      this.loadingBanDetails = true;
      this.loadingBanDetailsError = false;
      this.featuredBanId = banId;
      this.$refs.featuredBan.show();

      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/details`);
        url.searchParams.append('ban_id', this.featuredBanId);

        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.featuredBan = data.ban;
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        this.loadingBanDetailsError = true;
        console.log(`[ERROR] ${error}`);
      }
      this.loadingBanDetails = false;
    }
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    positionQuery: {
      get() {
        return `${this.query.position.coordinates[0]}, ${this.query.position.coordinates[1]}`;
      },
      set(val) {
        let f = val.match('^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$');
        if(f === null) return this.$refs.positionQueryInput.$el.classList.add('is-invalid');
        else this.$refs.positionQueryInput.$el.classList.remove('is-invalid');
        let x = f[1];
        let y = f[3];
        this.query.position.coordinates = [x, y];
      }
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  mounted() {
    //this.getData();
    this.fetchData().catch(error => {
      console.error(error)
    })
    this.banForm.permanent = this.options.permissions.ban.permanent;
  },
  data() {
    return {
      BanType: BanType,
      ready: false,
      error: false,
      banlist: null,
      // Table
      items: [],
      fields: [
        {
          key: 'created_at',
          label: this.$t('banmanager.view.database.columns.date'),
          sortable: true
        },
        {
          key: 'status',
          label: this.$t('banmanager.view.database.columns.status'),
          sortable: false
        },
        {
          key: 'identifier',
          label: this.$t('banmanager.view.database.columns.target'),
          sortable: true
        },
        {
          key: 'reason',
          label: this.$t('banmanager.view.database.columns.reason'),
          sortable: true
        },
        {
          key: 'expires_at',
          label: this.$t('banmanager.view.database.columns.expiration'),
          sortable: true
        },
        {
          key: 'rework_requested',
          label: 'Rework Requested',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('banmanager.view.database.columns.actions'),
          sortable: false
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,

      // Query
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: false,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      },

      // Add ban
      staticTrue: 1,
      banInProgress: false,
      identifierValid: true,
      banForm: {
        type: 'ipv4',
        identifier: null,
        reason: null,
        description: null,
        permanent: false,
        expiration: this.currentDateTime(),
        links: [],
        files: []
      },

      // Ban details
      loadingBanDetails: true,
      loadingBanDetailsError: false,
      featuredBanId: null,
      featuredBan: null,
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="c-table-header">
        <div class="c-table-title" v-on:click="filtersToggle()">
          <span class="badge badge-primary">
            <h6 class="mb-0 mt-0">
              {{ $t('components.table.filters.title') }}
              <small>
                {{ $t('components.table.filters.details') }}
              </small>
            </h6>

          </span>
        </div>
        <!-- Primary search term -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row">
          <div class="col-lg col-sm-12">
            <div class="mt-lg-2">
              {{$t('components.table.filters.search_term')}}
              <span class="badge badge-primary mb-1 c-table-st-clear" v-on:click="clearSearchTerm()">
                {{ $t('components.table.filters.clear') }}
              </span>
            </div>
            <b-form-input
                for="text"
                v-model="query.search_term"
                :placeholder="$t('components.table.filters.search_term')"
                v-on:keyup="$event.keyCode === 13 ? fetchData() : null"
            />
          </div>
        </div>
        <div class="c-table-exfilters" v-if="query.full">
          <!-- Date range -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.date.start.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.date.start') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                      id="date-time"
                      v-model="query.date.start.value"
                      type="datetime-local"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="c-table-sm-spacer"/> <!-- Spacer -->
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                    switch
                    v-model="query.date.end.enabled"
                    class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.date.end') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                    id="date-time"
                    v-model="query.date.end.value"
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Special features, GEO etc. -->
          <template v-if="query.position.available">
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.position.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.pos.coords') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <div class="input-group">
                    <b-form-input
                        v-model="positionQuery"
                        ref="positionQueryInput"
                    />
                    <div class="invalid-feedback">
                      <span>
                        {{ $t('components.table.filters.pos.error') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <div class="mt-lg-2">
                    {{ $t('components.table.filters.pos.radius') }}
                  </div>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <vue-slide-bar v-model="query.position.radius" :min="0" :max="1000" class="mt-n2" />
                </div>
              </div>
            </div>
          </div>
          </template>
        </div>
        <!-- Button -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-12">
            <button class="btn btn-primary btn-block" v-on:click="adhocSearch()">
              <i class="fad fa-search"></i>
              {{ $t('components.table.filters.search') }}
            </button>
          </div>
        </div>
        <div class="float-right" v-if="query.delta > 0">
          <small>Query duration {{ query.delta }}ms</small>
        </div>
      </div>
      <div class="mt-2">
        <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <orbit-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </div>
        <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template #empty="scope">
            <h4>{{ $t('components.table.filters.empty') }}</h4>
          </template>
          <template #cell(created_at)="data">
            {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
          </template>
          <template #cell(status)="data">
            <span class="badge badge-primary" v-if="data.item.meta.active">
              {{ $t('banmanager.view.database.active') }}
            </span>
            <span class="badge badge-warning text-black" v-else>
              {{ $t('banmanager.view.database.inactive') }}
            </span>

            <!-- Automated -->
            <button class="btn btn-sm btn-light ml-1" v-if="data.item.meta.flags.automated" v-b-tooltip.hover :title="$t('banmanager.view.database.automated')">
              <i class="fad fa-tasks"/>
            </button>

            <!-- Imported -->
            <button class="btn btn-sm btn-light ml-1" v-if="data.item.meta.flags.imported" v-b-tooltip.hover :title="$t('banmanager.view.database.imported')">
              <i class="fad fa-file-import"/>
            </button>

            <!-- Integration -->
            <button class="btn btn-sm btn-light ml-1" v-if="data.item.meta.flags.integration" v-b-tooltip.hover :title="$t('banmanager.view.database.integration')">
              <i class="fad fa-robot"/>
            </button>

            <!-- Shared -->
            <button class="btn btn-sm btn-light ml-1" v-if="data.item.meta.flags.shared" v-b-tooltip.hover :title="$t('banmanager.view.database.shared')">
              <i class="fad fa-share-all"/>
            </button>
          </template>
          <template #cell(identifier)="data">
            <template v-if="data.item.user.cftools_id">
              <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}" v-if="data.item.user.avatar">
                <img
                    :src="data.item.user.avatar"
                    alt="profile picture"
                    class="rounded-circle avatar-xs"
                >
                <template v-if="data.item.user.display_name">
                  {{ data.item.user.display_name }}
                </template>
                <template v-else>
                  {{ data.item.user.cftools_id }}
                </template>
              </router-link>
            </template>
            <template v-else>
              <b class="text-lowercase text-code font-size-14 text-white">
                {{ data.item.identifier }}
              </b>
            </template>
          </template>
          <template #cell(expires_at)="data">
            <template v-if="data.item.expires_at">
              {{ $d(parseDate(data.item.expires_at), 'datetime', getDTLocale()) }}
            </template>
            <template v-else>
              {{ $t('banmanager.view.database.never') }}
            </template>
          </template>
          <template #cell(rework_requested)="data">
            <template v-if="data.item.meta.flags.rework_requested === true">
              <button class="btn ml-1 mr-1 btn-sm btn-rounded btn-success text-black" v-on:click="toggleRevisionState(data.item.id, false)">
                Rework Sufficient
              </button>
            </template>
            <template v-else>
              <button class="btn ml-1 mr-1 btn-sm btn-rounded btn-danger text-black" v-on:click="toggleRevisionState(data.item.id, false)">
                Request Revision
              </button>
            </template>
          </template>
          <template #cell(actions)="data">
            <button class="btn ml-1 mr-1 btn-sm btn-rounded btn-warning text-black" v-on:click="revokeBan(data.item.id, false)" v-if="data.item.meta.active === true">
              <i class="far fa-eraser" />
              {{ $t('banmanager.view.database.revoke') }}
            </button>
            <button class="btn ml-1 mr-1 btn-sm btn-rounded btn-primary" v-on:click="detailedBanView(data.item.id)">
              <i class="fad fa-info" />
              {{ $t('banmanager.view.database.details') }}
            </button>
          </template>
        </b-table>
      </div>
      <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
    </div>
    <b-modal
        ref="featuredBan"
        size="lg"
        :title="'Ban #'+featuredBanId"
        title-class="font-18"
        hide-footer
    >
      <template v-if="!loadingBanDetails && !loadingBanDetailsError">
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-nowrap mb-0">
                <tbody>
                <tr>
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.status') }}</h6>
                  </th>
                  <td>
                    <template v-if="featuredBan.meta.active">
                      <span class="badge badge-primary">
                        {{ $t('banmanager.view.database.active') }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="badge badge-warning text-black">
                        {{ $t('banmanager.view.database.inactive') }}
                      </span>
                    </template>
                    <span class="small">
                      {{ $t('banmanager.view.database.expires') }}:
                      <template v-if="featuredBan.expires_at">
                        {{ $d(parseDate(featuredBan.expires_at), 'datetime', getDTLocale()) }}
                      </template>
                      <template v-else>
                        {{ $t('banmanager.view.database.never') }}
                      </template>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.type.label') }}</h6>
                  </th>
                  <td>
                    <template v-if="featuredBan.type === BanType.CFTOOLS_ID">
                      <!-- Account ban -->
                      {{ $t('banmanager.details.type.cftools_id') }}
                    </template>
                    <template v-else-if="featuredBan.type === BanType.IPV4">
                      <!-- Dynamic ip ban -->
                      {{ $t('banmanager.details.type.ipv4') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.identifier') }}</h6>
                  </th>
                  <td>
                    <div style="max-width: 20rem !important;">
                      <CopyableInput size="sm" :text="featuredBan.identifier" :label="featuredBan.identifier" />
                    </div>
                  </td>
                </tr>
                <tr v-if="featuredBan.user.cftools_id">
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.player') }}</h6>
                  </th>
                  <td>
                    <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: featuredBan.user.cftools_id}}" v-if="featuredBan.user.cftools_id">
                      <img
                          :src="featuredBan.user.avatar"
                          alt="profile picture"
                          class="rounded-circle avatar-xs"
                          v-if="featuredBan.user.avatar"
                      >
                      <template v-if="!featuredBan.user.cftools_id">
                        {{ featuredBan.user.cftools_id }}
                      </template>
                      <template v-else>
                        {{ featuredBan.user.display_name }}
                      </template>
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.date') }}</h6>
                  </th>
                  <td>
                    {{ $d(parseDate(featuredBan.created_at), 'datetime', getDTLocale()) }},<time-ago  :refresh="60" :datetime="parseDate(featuredBan.created_at)" long :locale="getUILanguage()"></time-ago>
                  </td>
                </tr>
                <tr>
                  <template v-if="featuredBan.issuer.cftools_id">
                    <th scope="row">
                      <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.issuer') }}</h6>
                    </th>
                    <td>
                      <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: featuredBan.issuer.cftools_id}}" v-if="featuredBan.issuer.cftools_id">
                        <img
                            :src="featuredBan.issuer.avatar"
                            alt="profile picture"
                            class="rounded-circle avatar-xs"
                        >
                        {{ featuredBan.issuer.display_name }}
                      </router-link>
                    </td>
                  </template>
                  <template v-else>
                    <th scope="row">
                      <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.source') }}</h6>
                    </th>
                    <td>
                      <template v-if="featuredBan.meta.flags.automated">
                        {{ $t('banmanager.view.database.automated') }}
                      </template>
                      <template v-else-if="featuredBan.meta.flags.imported">
                        {{ $t('banmanager.view.database.imported') }}
                      </template>
                      <template v-else-if="featuredBan.meta.flags.integration">
                        {{ $t('banmanager.view.database.integration') }}
                      </template>
                      <template v-else-if="featuredBan.meta.flags.shared">
                        {{ $t('banmanager.view.database.shared') }}
                      </template>
                    </td>
                  </template>
                </tr>
                <tr>
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.reason') }}</h6>
                  </th>
                  <td>
                    {{ featuredBan.reason }}
                  </td>
                </tr>
                <tr v-if="featuredBan.description && featuredBan.description !== 'None'">
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.description') }}</h6>
                  </th>
                  <td>
                    {{ featuredBan.description }}
                  </td>
                </tr>
                <tr v-if="featuredBan.evidence.files.length">
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.files') }}</h6>
                  </th>
                  <td>
                    <ul class="ml-0 pl-0" style="list-style: none;">
                      <li v-for="file in featuredBan.evidence.files" :key="file[0]+Math.random()">
                        <i class="fad fa-file-alt align-middle"></i>
                        <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                          {{file[1]}}
                        </a>
                        <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                        <span class="ml-1 small text-muted">
                          {{$t('support.read.actions.download')}}
                        </span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr v-if="featuredBan.evidence.links.length">
                  <th scope="row">
                    <h6 class="text-uppercase mb-0">{{ $t('banmanager.details.links') }}</h6>
                  </th>
                  <td>
                    <ul class="ml-0 pl-0" style="list-style: none;">
                      <li v-for="link in featuredBan.evidence.links" :key="link+Math.random()">
                        <i class="fad fa-external-link align-middle"></i>
                        <a :href="link" target="_blank">
                          {{link}}
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-warning text-black btn-block" v-if="featuredBan.meta.active" v-on:click="revokeBan(featuredBan.id, false)">
              {{ $t('banmanager.details.revoke') }}
            </button>
            <button class="btn btn-outline-danger btn-block" v-if="featuredBan.allowDelete" v-on:click="revokeBan(featuredBan.id, true)">
              {{ $t('banmanager.details.delete') }}
            </button>
          </div>
        </div>
      </template>
      <template v-else-if="!loadingBanDetails && loadingBanDetailsError">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                  <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="loadingBanDetails">
        <div  class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <orbit-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
